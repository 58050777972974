.user_detail {
    .active_btn {
        color: #0ab845 !important;
        border-color: #0ab845 !important;
        :hover {
            opacity: 0.6;
        }
    }
    .user_info_card {
        flex: 1;
        border-radius: 20px;
        border: 1px solid #dbdbdb;
        margin-right: 20px;
        padding: 20px 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .info_item {
            .info_label {
                font-size: 16px;
                font-family: Montserrat-Medium;
                font-weight: 500;
                color: rgba(27, 27, 27, 0.6);
                line-height: 22px;
            }
            .info_content {
                margin-left: 18px;
                font-size: 16px;
                font-family: Montserrat-Medium;
                font-weight: 500;
                color: #1b1b1b;
                line-height: 22px;
            }
        }
    }
    .user_data {
        min-width: 426px;
        .user_data_card {
            &_title {
                font-size: 16px;
                font-family: Montserrat-Medium;
                font-weight: 500;
                color: #1b1b1b;
                line-height: 22px;
            }
            &_tip {
                font-size: 14px;
                font-family: Montserrat-Medium;
                font-weight: 500;
                color: rgba(27, 27, 27, 0.6);
                line-height: 22px;
                text-align: center;
                margin-top: 20px;
            }
            &_quantity {
                font-size: 42px;
                font-family: Montserrat-SemiBold;
                font-weight: 600;
                color: #1b1b1b;
                text-align: center;
                line-height: 1;
                word-wrap: break-word;
            }
            .split_line {
                width: 2px;
                height: 80px;
                background-color: #f6f6f6;
            }
        }
        .monthly_leaderboard {
            padding: 25px 0;
            // height: 270px;
            border-radius: 20px;
            border: 1px solid #dbdbdb;
        }
        .total_points,
        .total_fitloot {
            padding: 0 30px;
            margin-top: 20px;
            height: 150px;
            border-radius: 20px;
            border: 1px solid #dbdbdb;
            .user_data_card_title {
                margin-bottom: 15px;
            }
        }
        .total_points {
            .user_data_card_title {
                width: 226px;
            }
        }
    }
    .total_card_item_title {
        font-size: 24px;
        font-family: Montserrat-Bold;
        font-weight: bold;
        color: #1b1b1b;
        line-height: 24px;
    }
    .total_card_item {
        // height: 68px;
        border-radius: 20px;
        border: 1px solid #dbdbdb;
        padding: 23px 30px;
        margin: 20px 0;
        &_label {
            font-size: 16px;
            font-family: Montserrat-Medium;
            font-weight: 500;
            color: rgba(27, 27, 27, 0.6);
        }
        &_quantity {
            margin-left: 20px;
            font-size: 16px;
            font-family: Montserrat-Medium;
            font-weight: 500;
            color: #1b1b1b;
        }
    }
}
.points_received {
    color: #0ab845;
}
.points_received_negative {
    color: #ff3b30;
}
.group_detail {
    &_container {
        &_left {
            .group_detail_introduction {
                padding: 0 9px 26px 30px;
                border-radius: 20px;
                border: 1px solid #dbdbdb;
                .group_detail_introduction_item {
                    margin-top: 20px;
                }
                &_title,
                &_value {
                    font-size: 16px;
                    font-family: Montserrat-Medium;
                    font-weight: 500;
                    color: rgba(27, 27, 27, 0.6);
                    line-height: 22px;
                }
                &_value {
                    margin-left: 20px;
                    color: rgba(27, 27, 27, 1);
                }
            }
            .group_detail_members {
                padding: 23px 14px;
                margin-top: 20px;
                height: 458px;
                border-radius: 20px;
                border: 1px solid #dbdbdb;
                &_title {
                    margin: 0 16px;
                    font-size: 16px;
                    font-family: Montserrat-Medium;
                    font-weight: 500;
                    color: rgba(27, 27, 27, 0.6);
                    line-height: 22px;
                }
                .ant-table-cell {
                    padding: 9px 14px !important;
                }
            }
        }
        &_right {
            padding: 40px 14px;
            height: 714px;
            border-radius: 20px;
            border: 1px solid #dbdbdb;
        }
    }
}
.user_workout_detail {
    .workout_detail_introduction {
        padding: 20px 30px;
        border-radius: 20px;
        border: 1px solid #dbdbdb;
        margin-bottom: 20px;
        &_title {
            font-size: 24px;
            font-family: Montserrat-Bold;
            font-weight: bold;
            color: #1b1b1b;
            margin-bottom: 20px;
            cursor: pointer;
        }
        &_item {
            line-height: 40px;
        }
        &_label,
        &_value {
            font-size: 16px;
            font-family: Montserrat-Medium;
            font-weight: 500;
            color: rgba(27, 27, 27, 0.6);
            line-height: 22px;
        }
        &_value {
            margin-left: 20px;
            color: rgba(27, 27, 27, 1);
        }
    }
}
