.post_detail {
    .post_user,
    .post_workout,
    .post_content_card,
    .post_reactions_card,
    .post_comments_card {
        margin-bottom: 20px;
        padding: 20px 30px;
        border-radius: 20px;
        line-height: 32px;
        border: 1px solid #dbdbdb;
        .post_card_label {
            font-size: 16px;
            font-family: Montserrat-Medium;
            font-weight: 500;
            color: rgba(27, 27, 27, 0.6);
        }
        .post_card_value {
            font-size: 16px;
            font-family: Montserrat-Medium;
            font-weight: 500;
            color: rgba(27, 27, 27, 1);
            margin-left: 20px;
        }
        .post_user_box {
            margin-bottom: 10px;
            .post_user_name {
                font-size: 24px;
                font-family: Montserrat-SemiBold;
                font-weight: 600;
                color: #1b1b1b;
                line-height: 22px;
                margin-left: 15px;
                .to_user_detail {
                    margin-top: 12px;
                    width: 90px;
                    height: 24px;
                    border-radius: 17px;
                    border: 1px solid #0085ff;
                    cursor: pointer;
                    font-size: 10px;
                    font-family: Montserrat-SemiBold;
                    font-weight: 600;
                    color: #0085ff;
                    text-align: center;
                }
            }
        }
        .arrowTransform {
            transition: 0.2s;
            transform: rotate(180deg);
        }

        .arrowTransformReturn {
            transition: 0.2s;
            transform: rotate(0deg);
        }
        .reactions_types {
            margin-top: 15px;
        }
        .reactions_type {
            display: flex;
            align-items: center;
            .reactions_all {
                margin-right: 15px;
                .response_person {
                    margin-left: 5px;
                    font-size: 12px;
                    font-family: Montserrat-Medium;
                    font-weight: 500;
                    color: #1b1b1b;
                    line-height: 19px;
                }
            }
        }
        .workout_video_box {
            position: relative;
            .play_icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    .post_content_card {
        .ant-space-item {
            height: 120px;
            .ant-image-img {
                border-radius: 4px;
                object-fit: cover;
            }
            video {
                border-radius: 4px;
            }
        }
    }
    .ant-collapse-header,
    .ant-collapse-content-box {
        padding: 0 !important;
    }
    .post_workout {
        height: 298px;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 8px;
        }
        &::-webkit-scrollbar-button {
            background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
            width: 8px;
            background-color: #a2a2a4;
            border-radius: 4px;
        }
    }
}
.ant-table-row-expand-icon-cell {
    position: relative;
    .view_more {
        position: absolute;
        left: -103px;
        width: 100%;
        height: 100%;
        top: 0;
        display: flex;
        align-items: center;
    }
}
.CommentList {
    .ant-table-expanded-row {
        display: none;
    }
}
.disabled_btn {
    background: transparent !important;
}
.search_box {
    .report_all {
        position: relative;
        .unread_badge {
            position: absolute;
            right: 0;
            top: -10px;
            background-color: #ff3b30;
            border-radius: 60px;
            padding: 4px 9px;
            z-index: 99;
            font-size: 10px;
            font-family: Montserrat-Bold;
            font-weight: bold;
            color: #ffffff;
        }
    }
}
