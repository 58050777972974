/*
 * @author        r.shouwei <r.shouwei@technehq.com>
 * @date          2023-05-08 11:43:08
 * Copyright © Techne All rights reserved
 */
.workout-detail_main {
    padding-top: 57px;
    .space15 {
        width: 15px;
        height: 100%;
    }
    .space30 {
        width: 30px;
        height: 1px;
    }
    .mar20 {
        margin: 20px 0;
    }
    .top_back {
        .back_box {
            font-size: 16px;
            font-family: Montserrat-SemiBold, Montserrat;
            font-weight: 600;
            color: #1b1b1b;
            width: 68px;
            cursor: pointer;
            .back_icon {
                width: 18px;
                height: 18px;
                background: url('../../../../assets/images/back.png') no-repeat;
                background-size: contain;
                cursor: pointer;
                margin-right: 8px;
            }
        }
    }
    .detail_box {
        .info_row {
            margin-bottom: 20px;
            &:last-child {
                margin: 0;
            }
            .info_tip,
            .info_msg {
                font-size: 16px;
                font-family: Montserrat-Medium, Montserrat;
                font-weight: 500;
                color: #767676;
                line-height: 22px;
            }
            .info_tip {
                min-width: max-content;
            }
            .info_msg {
                color: #1b1b1b;
                margin-left: 20px;
            }
            .no-margin {
                margin: 0;
            }
        }
        .topNum_ul {
            padding: 35px 0 20px 0;
            width: 100%;
            .wordout_card {
                // flex: 1;
                width: calc(33.3% - 7px);
                height: 150px;
                background: #ffffff;
                border-radius: 20px 20px 20px 20px;
                border: 1px solid #dbdbdb;
                padding: 30px;
                &.space_box {
                    margin: 0 20px;
                }
                .card_left {
                    .card_label {
                        width: 210px;
                        font-size: 16px;
                        font-family: Montserrat-Medium, Montserrat;
                        font-weight: 500;
                        color: #1b1b1b;
                        line-height: 22px;
                        margin-bottom: 10px;
                    }
                }
                .card_val {
                    font-size: 42px;
                    font-family: Montserrat-SemiBold, Montserrat;
                    font-weight: 600;
                    color: #1b1b1b;
                    line-height: 24px;
                }
            }
        }
        .workout_infoBox {
            .info-leftbox {
                // flex: 2.06;
                width: calc(66.6% - 7px);
                background: #ffffff;
                border-radius: 20px 20px 20px 20px;
                border: 1px solid #dbdbdb;
                min-height: 240px;
                padding: 22px 30px;
            }
            .info-rightbox {
                flex: 1;
                // min-height: 404px;
                margin-left: 20px;
                background: #ffffff;
                border-radius: 20px 20px 20px 20px;
                border: 1px solid #dbdbdb;
                padding: 22px 30px;
                overflow-y: auto;
            }
        }
        .workout_composition {
            margin: 20px 0;
            .composition_card {
                flex: 1;
                height: 118px;
                background: #ffffff;
                border-radius: 20px 20px 20px 20px;
                opacity: 1;
                border: 1px solid #dbdbdb;
                padding: 24px 30px;
                &:last-child {
                    margin-left: 20px;
                }
                .composition_tit {
                    font-size: 24px;
                    font-family: Montserrat-Bold, Montserrat;
                    font-weight: bold;
                    color: #1b1b1b;
                    line-height: 24px;
                    margin-bottom: 20px;
                }
                .composition_val {
                    font-size: 16px;
                    font-family: Montserrat-Medium, Montserrat;
                    font-weight: 500;
                    color: #1b1b1b;
                    line-height: 22px;
                }
            }
        }
        .workouts_action {
            width: 100%;
            // min-height: 234px;
            background: #ffffff;
            border-radius: 20px 20px 20px 20px;
            opacity: 1;
            border: 1px solid #dbdbdb;
            padding: 24px 30px;
            .action_tit {
                font-size: 24px;
                font-family: Montserrat-Bold, Montserrat;
                font-weight: bold;
                color: #1b1b1b;
                line-height: 24px;
                margin-bottom: 20px;
            }
            .action_setBox {
                .action_setTit {
                    font-size: 16px;
                    font-family: Montserrat-SemiBold, Montserrat;
                    font-weight: 600;
                    color: #000000;
                    line-height: 21px;
                    margin-top: 20px;
                }
                .action_labelBox {
                    margin: 10px 0;
                    .action_label {
                        font-size: 16px;
                        font-family: Montserrat-Medium, Montserrat;
                        font-weight: 500;
                        color: #1b1b1b;
                        line-height: 22px;
                        .exerciseNameBox {
                            color: #0085ff;
                            cursor: pointer;
                            text-decoration: underline;
                            user-select: none;
                        }
                        .space30 {
                            width: 30px;
                            height: 1px;
                        }
                    }
                }
            }
        }
        .workout-location-statistics {
            width: 100%;
            background: #ffffff;
            border-radius: 20px 20px 20px 20px;
            padding: 23px 30px;
            border: 1px solid #dbdbdb;
            .location_statisticsTip {
                font-size: 16px;
                font-family: Montserrat-Medium, Montserrat;
                font-weight: 500;
                color: #767676;
                line-height: 22px;
                .view_box {
                    font-size: 14px;
                    font-family: Montserrat-SemiBold, Montserrat;
                    font-weight: 600;
                    color: #0085ff;
                    text-decoration: underline;
                    cursor: pointer;
                    margin-left: auto;
                }
            }
            .workout_list {
                .workout_item_header {
                    padding: 20px 0 15px 0;
                    .itemHeader_cell {
                        font-size: 13px;
                        font-family: Montserrat-SemiBold, Montserrat;
                        font-weight: 600;
                        color: #767676;
                        line-height: 22px;
                        flex: 3;
                        &:last-child {
                            flex: 1;
                        }
                    }
                }
                .workout_item {
                    margin-bottom: 18px;
                    .item_cell {
                        flex: 3;
                        font-size: 14px;
                        font-family: Montserrat-SemiBold, Montserrat;
                        font-weight: 600;
                        color: #1b1b1b;
                        &:last-child {
                            flex: 1;
                        }
                        .user_img {
                            width: 24px;
                            height: 24px;
                            object-fit: cover;
                            border-radius: 60px 60px 60px 60px;
                            margin-right: 9px;
                            background: skyblue;
                        }
                    }
                    .item_cellMini {
                        font-size: 12px;
                        font-family: Montserrat-Medium, Montserrat;
                        font-weight: 500;
                        color: #767676;
                    }
                }
            }
        }
    }
}
