/*
 * @author        r.shouwei <r.shouwei@technehq.com>
 * @date          2023-05-08 10:18:11
 * Copyright © Techne All rights reserved
 */
//workout list
.workout_main {
    padding-top: 50px;
    .workout_top {
        margin-bottom: 30px;
        .space15 {
            width: 15px;
            height: 100%;
        }
        .r-default_btn {
            position: relative;
            .roundNum_box {
                position: absolute;
                padding: 0 5px;
                min-width: 26px;
                height: 20px;
                background: #ff3b30;
                border-radius: 60px 60px 60px 60px;
                top: -10px;
                right: -1px;
                font-size: 10px;
                font-family: Montserrat-Bold, Montserrat;
                font-weight: bold;
                color: #ffffff;
                // letter-spacing: 1px;
                text-align: center;
                line-height: 20px;
            }
        }
    }
    .table_main {
        .remove_btn {
            width: 99px;
            height: 32px;
            border-radius: 17px 17px 17px 17px;
            opacity: 1;
            border: 1px solid #dbdbdb;
            text-align: center;
            line-height: 32px;
            font-size: 12px;
            font-family: Montserrat-SemiBold, Montserrat;
            font-weight: 600;
            color: #1b1b1b;
            user-select: none;
            cursor: pointer;
        }
    }
}
//workout main back
.workout-list_main {
    padding-top: 57px;
    .space15 {
        width: 15px;
        height: 100%;
    }
    .space10 {
        width: 10px;
        height: 100%;
    }
    .mar20 {
        margin: 20px 0;
    }
    .top_back {
        .back_box {
            font-size: 16px;
            font-family: Montserrat-SemiBold, Montserrat;
            font-weight: 600;
            color: #1b1b1b;
            width: 68px;
            cursor: pointer;
            .back_icon {
                width: 18px;
                height: 18px;
                background: url('../../../../assets/images/back.png') no-repeat;
                background-size: contain;
                cursor: pointer;
                margin-right: 8px;
            }
        }
    }
}
//workout user statistics list
.user_statistics_main {
    padding-top: 72px;
    .screening_box {
        margin: 40px 0 30px 0;
    }
}
//workout user feedback
.user_feedback_main {
    padding-top: 72px;
    .screening_box {
        margin: 40px 0 30px 0;
    }
    .feedback_tab {
        margin-bottom: 20px;
        .feedback_tab_item {
            padding: 8px 18px;
            font-size: 12px;
            font-family: Montserrat-SemiBold, Montserrat;
            font-weight: 600;
            color: #767676;
            line-height: 18px;
            position: relative;
            border-radius: 4px;
            cursor: pointer;
            user-select: none;
            transition: all 0.2s;
            .new_round {
                position: absolute;
                width: 6px;
                height: 6px;
                background: #ff3b30;
                border-radius: 50%;
                left: 5px;
                top: 14px;
            }
            &:last-child {
                margin-left: 20px;
            }
            &.active {
                background: #0085ff;
                color: #ffffff;
                .new_round {
                    display: none;
                }
            }
        }
    }
    .new_btn {
        width: 99px;
        height: 32px;
        border-radius: 17px 17px 17px 17px;
        border: 1px solid #ff3b30;
        font-size: 12px;
        font-family: Montserrat-SemiBold, Montserrat;
        font-weight: 600;
        color: #ff3b30;
        line-height: 32px;
        text-align: center;
        cursor: pointer;
    }
    .actioned_btn {
        color: #1b1b1b;
        border: 1px solid #dbdbdb;
        cursor: no-drop;
    }
}
//workout Action Library
.workoutActionLibrary_main {
    padding-top: 72px;
    .screening_box {
        margin: 40px 0 30px 0;
    }
    .action_tab {
        margin-bottom: 20px;
        .action_tab_item {
            width: 152px;
            text-align: center;
            padding: 8px 18px;
            font-size: 12px;
            font-family: Montserrat-SemiBold, Montserrat;
            font-weight: 600;
            color: #767676;
            line-height: 18px;
            position: relative;
            border-radius: 4px;
            cursor: pointer;
            user-select: none;
            transition: all 0.2s;
            &:nth-child(2) {
                margin: 0 20px;
            }
            &:last-child {
                margin: 0 20px;
            }
            &.active {
                background: #0085ff;
                color: #ffffff;
            }
        }
    }
    .skill_levelbox {
        // padding-top: 20px;
        font-size: 14px;
        font-family: Montserrat-SemiBold, Montserrat;
        font-weight: 600;
        color: #0085ff;
        line-height: 22px;
        cursor: pointer;
        text-decoration: underline;
        user-select: none;
    }
    .actioned_btn {
        width: 99px;
        height: 32px;
        border-radius: 17px 17px 17px 17px;
        font-size: 12px;
        font-family: Montserrat-SemiBold, Montserrat;
        font-weight: 600;
        line-height: 32px;
        text-align: center;
        cursor: pointer;
        color: #1b1b1b;
        border: 1px solid #dbdbdb;
    }
    .action_library_list {
        .leavel_box {
            padding-top: 20px;
            align-items: flex-start;
            .leavel_left {
                margin-left: 22px;
                height: 112px;
                .border_levael,
                .border_levael_m {
                    width: 16px;
                    height: 22px;
                    border-left: 2px solid #eceef0;
                    border-bottom: 2px solid #eceef0;
                }
                .border_levael_m {
                    height: 45px;
                }
            }
            .leavel_right {
                height: 122px;
                margin-left: 14px;
                width: 550px;
                .tip_levael {
                    display: flex;
                    align-items: center;
                    font-size: 13px;
                    font-family: Montserrat-SemiBold, Montserrat;
                    font-weight: 600;
                    color: #909090;
                    line-height: 22px;
                    margin: 11px 0;
                    &:first-child {
                        margin: 0px 0 0 0;
                    }
                    &:last-child {
                        margin: 0;
                    }
                    .file_iconImg {
                        margin-left: auto;
                    }
                }
            }
        }
    }
}
// action library detail
.actionlibrary_detail_main {
    padding-top: 57px;
    .space15 {
        width: 15px;
        height: 100%;
    }
    .marB20 {
        margin: 0 0 20px 0;
    }
    .detail_box {
        .info_row {
            margin-bottom: 20px;
            &:last-child {
                margin: 0;
            }
            .info_tip,
            .info_msg {
                font-size: 16px;
                font-family: Montserrat-Medium, Montserrat;
                font-weight: 500;
                color: #767676;
                line-height: 22px;
            }
            .info_tip {
                min-width: max-content;
            }
            .info_msg {
                color: #1b1b1b;
                margin-left: 20px;
            }
            .no-margin {
                margin: 0;
            }
            .info_tit_action {
                font-size: 24px;
                font-family: Montserrat-Bold, Montserrat;
                font-weight: bold;
                color: #1b1b1b;
                line-height: 24px;
            }
            .info_mini_tit {
                font-size: 16px;
                font-family: Montserrat-SemiBold, Montserrat;
                font-weight: 600;
                color: #000000;
                line-height: 21px;
            }
            .info_video_box {
                position: relative;
                margin-left: 20px;
                cursor: pointer;
                .play_icon {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
                .gif-container {
                    width: 120px;
                    height: 120px;
                    border-radius: 4px;
                    background-size: cover;
                    background-position: center;
                    background-color: #dbdbdb;
                }
            }
            .info_video {
                width: 120px;
                height: 120px;
                border-radius: 4px;
                // background-color: #dbdbdb;
                border: 1px solid #dbdbdb;
                object-fit: cover;
            }
        }
        .workout_infoBox {
            margin-top: 35px;
            align-items: flex-start;
            .info-leftbox {
                flex: 2;
                .action_msgBox {
                    background: #ffffff;
                    border-radius: 20px 20px 20px 20px;
                    border: 1px solid #dbdbdb;
                    padding: 22px 30px;
                }
            }
            .info-rightbox {
                flex: 1;
                // min-height: 404px;
                margin-left: 20px;
                background: #ffffff;
                border-radius: 20px 20px 20px 20px;
                border: 1px solid #dbdbdb;
                padding: 22px 30px;
            }
        }
    }
}
// create action library
.createActionlibrary_main {
    .actionInfo_form {
        width: 920px;
        .form_tit {
            font-size: 24px;
            font-family: Montserrat-SemiBold, Montserrat;
            font-weight: 600;
            color: #1b1b1b;
            line-height: 22px;
            margin: 25px 0 20px 0;
        }
        .form_tip {
            font-size: 12px;
            font-family: Montserrat-SemiBold, Montserrat;
            font-weight: 600;
            color: #1b1b1b;
            line-height: 19px;
            margin: 20px 0 8px 0;
        }
        .form_tip_mid {
            font-size: 16px;
            font-family: Montserrat-SemiBold, Montserrat;
            font-weight: 600;
            color: #000000;
            line-height: 21px;
            margin: 30px 0;
        }
        .upload_text {
            font-size: 14px;
            font-family: Montserrat-SemiBold, Montserrat;
            font-weight: 600;
            color: #0085ff;
            line-height: 19px;
            text-decoration: underline;
            cursor: pointer;
            margin-top: 20px;
        }
        .file_showBox {
            width: 330px;
            height: 70px;
            background: #ffffff;
            border-radius: 6px;
            border: 1px solid #c9cacc;
            padding: 15px 20px;
            margin-top: 20px;
            .video_icon {
                width: 40px;
                min-width: 40px;
                height: 40px;
                background: url('../../../../assets/images/video-file.png') no-repeat;
                background-size: contain;
                margin-right: 15px;
            }
            .video_name {
                max-width: 170px;
                font-size: 14px;
                font-family: Montserrat-SemiBold, Montserrat;
                font-weight: 600;
                color: #1b1b1b;
                line-height: 19px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .video_size {
                font-size: 12px;
                font-family: Montserrat-Medium, Montserrat;
                font-weight: 500;
                color: #777777;
                line-height: 19px;
                margin-top: 5px;
            }
            .close_icon {
                width: 24px;
                height: 24px;
                background: url('../../../../assets/images/Icons_Cross.png') no-repeat;
                background-size: contain;
                cursor: pointer;
                margin-left: auto;
            }
        }
    }
}
//
.add_workOut_page {
    .space10 {
        width: 10px;
        height: 100%;
    }
    .space20 {
        width: 100%;
        height: 20px;
    }
    .primary_tit {
        font-size: 16px;
        font-family: Montserrat-SemiBold, Montserrat;
        font-weight: 600;
        color: #000000;
        line-height: 21px;
        margin-top: 40px;
    }
    .medium_tit {
        font-size: 14px;
        font-family: Montserrat-SemiBold, Montserrat;
        font-weight: 600;
        color: #000000;
        line-height: 21px;
        margin: 20px 0 0 0;
    }
    .workout_switchTip {
        margin: 0 72px 0 0 !important;
    }
    #googleMap {
        width: 920px;
        height: 320px;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        background-color: #ccc;
        // .gm-style img {
        //     width: 28px !important;
        //     height: 35px !important;
        // }
    }
    .location_list {
        margin-top: 10px;
        width: 920px;
        // height: 147px;
        max-height: 178px;
        overflow-y: auto;
        background: #ffffff;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #c9cacc;
        padding: 13px 0;
        .location_item {
            display: flex;
            align-items: center;
            padding: 5px 19px;
            &:hover,
            &.hovered {
                background-color: #f1f3f5;
            }
            .location_text {
                flex: 1;
                font-size: 12px;
                font-family: Montserrat-Medium, Montserrat;
                font-weight: 500;
                color: #1b1b1b;
                line-height: 20px;
            }
        }
    }
    .opeation_edit_icon,
    .opeation_del_icon {
        width: 20px;
        height: 20px;
        cursor: pointer;
        background: url('../../../../assets/images/edit-rounded.png') no-repeat;
        background-size: contain;
    }
    .opeation_del_icon {
        margin-left: 24px;
        background: url('../../../../assets/images/del-rounded.png') no-repeat;
    }
    .add_new_btn {
        width: 150px;
        height: 32px;
        border-radius: 17px 17px 17px 17px;
        opacity: 1;
        border: 1px solid #0085ff;
        text-align: center;
        line-height: 32px;
        cursor: pointer;
        user-select: none;
        font-size: 12px;
        font-family: Montserrat-SemiBold, Montserrat;
        font-weight: 600;
        color: #0085ff;
    }
    .del_new_btn {
        width: 150px;
        height: 32px;
        border-radius: 17px 17px 17px 17px;
        opacity: 1;
        border: 1px solid #ff3b30;
        text-align: center;
        line-height: 32px;
        cursor: pointer;
        user-select: none;
        font-size: 12px;
        font-family: Montserrat-SemiBold, Montserrat;
        font-weight: 600;
        color: #ff3b30;
    }
    .marR20 {
        margin-right: 10px;
    }
    .positon_r {
        position: relative;
        .del_icon_posi {
            position: absolute;
            right: -30px;
            margin: 0;
            top: 60px;
        }
    }
}
//
.modal_form_vidoe {
    width: 100%;
    padding: 20px;
    .info_video {
        width: 100%;
        height: auto;
        border-radius: 4px;
        border: 1px solid #dbdbdb;
        max-height: 500px;
    }
    .img_info {
        object-fit: cover;
        min-height: 250px;
    }
}
// 1.1 version
.checkUl_new {
    justify-content: space-between;
    .checkItem_new {
        display: flex;
        align-items: center;
        width: 300px;
        height: 46px;
        background: #ffffff;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #c9cacc;
        padding: 13px 15px 13px 20px;
        font-size: 12px;
        font-family: Montserrat-Medium, Montserrat;
        cursor: pointer;
        user-select: none;
        &.disabled {
            cursor: no-drop;
        }
        .check_icon {
            width: 16px;
            height: 16px;
            margin-left: auto;
            cursor: pointer;
            background: url('../../../../assets/images/Checkbox.png') no-repeat;
            background-size: contain;
            &.checked_icon {
                background: url('../../../../assets/images/Checkboxed.png') no-repeat;
                background-size: contain;
            }
        }
    }
}
