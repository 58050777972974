.delete_instructions {
    width: 100%;
    height: 100vh;
    .logo {
        border-top: 8px solid #0085ff;
        background: #0d0d0d;
        padding: 22px 32px;
        img {
            width: 170px;
            height: 42px;
        }
    }
    &_content {
        margin: 40px 32px 27px;
        border-bottom: 2px solid #eceef0;
        padding-bottom: 10px;
        h1 {
            font-size: 24px;
            font-family: MontserratBold;
            font-weight: bold;
            color: #1b1b1b;
            line-height: 29px;
            margin-bottom: 16px;
        }
        .step {
            font-size: 14px;
            font-family: Montserrat-Medium, Montserrat;
            font-weight: 500;
            color: #1b1b1b;
            margin-bottom: 30px;
        }
    }
    &_footer {
        margin: 0 32px;
        padding-bottom: 40px;
        font-size: 12px;
        font-family: Montserrat-Medium, Montserrat;
        font-weight: 500;
        color: #1b1b1b;
        line-height: 18px;
        .footer_logo {
            width: 107px;
            height: 24px;
            margin-bottom: 20px;
        }
        a {
            color: #0085ff;
            font-weight: bold;
            text-decoration: none;
        }
    }
}
