body {
    background: #f3f3f3;
}
.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.flex-around {
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.flex-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.flex-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.flex-warp {
    display: flex;
    flex-wrap: wrap;
}
img {
    width: 100%;
    height: 100%;
    vertical-align: baseline;
}
