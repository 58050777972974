/*
 * @author        r.shouwei <r.shouwei@technehq.com>
 * @date          2023-05-09 16:00:00
 * Copyright © Techne All rights reserved
 */
.rewards_main {
    padding-top: 50px;
    .workout_top {
        margin-bottom: 40px;
        .space15 {
            width: 15px;
            height: 100%;
        }
        .r-default_btn {
            position: relative;
            .roundNum_box {
                position: absolute;
                padding: 0 5px;
                min-width: 26px;
                height: 20px;
                background: #ff3b30;
                border-radius: 60px 60px 60px 60px;
                top: -10px;
                right: -1px;
                font-size: 10px;
                font-family: Montserrat-Bold, Montserrat;
                font-weight: bold;
                color: #ffffff;
                // letter-spacing: 1px;
                text-align: center;
                line-height: 20px;
            }
        }
    }
    .action_tab {
        margin-bottom: 20px;
        .action_tab_item {
            width: 150px;
            text-align: center;
            padding: 8px 18px;
            font-size: 12px;
            font-family: Montserrat-SemiBold, Montserrat;
            font-weight: 600;
            color: #767676;
            line-height: 18px;
            position: relative;
            border-radius: 4px;
            cursor: pointer;
            user-select: none;
            transition: all 0.2s;
            &:nth-child(2) {
                margin: 0 20px;
            }
            &.active {
                background: #0085ff;
                color: #ffffff;
            }
        }
    }
    .table_main {
        .remove_btn {
            width: 99px;
            height: 32px;
            border-radius: 17px 17px 17px 17px;
            opacity: 1;
            border: 1px solid #dbdbdb;
            text-align: center;
            line-height: 32px;
            font-size: 12px;
            font-family: Montserrat-SemiBold, Montserrat;
            font-weight: 600;
            color: #1b1b1b;
            user-select: none;
            cursor: pointer;
        }
    }
}
//main back
.list_main {
    padding-top: 57px;
    .space15 {
        width: 15px;
        height: 100%;
    }
    .mar20 {
        margin: 20px 0;
    }
    .top_back {
        .back_box {
            font-size: 16px;
            font-family: Montserrat-SemiBold, Montserrat;
            font-weight: 600;
            color: #1b1b1b;
            width: 68px;
            cursor: pointer;
            .back_icon {
                width: 18px;
                height: 18px;
                background: url('../../../../assets/images/back.png') no-repeat;
                background-size: contain;
                cursor: pointer;
                margin-right: 8px;
            }
        }
    }
}
.rewards_pool_main {
    padding-top: 72px;
    .screening_box {
        margin: 40px 0 30px 0;
    }
    .skill_levelbox {
        font-size: 14px;
        font-family: Montserrat-SemiBold, Montserrat;
        font-weight: 600;
        color: #0085ff;
        line-height: 22px;
        cursor: pointer;
        text-decoration: underline;
        user-select: none;
    }
    .actioned_btn {
        width: 99px;
        height: 32px;
        border-radius: 17px 17px 17px 17px;
        font-size: 12px;
        font-family: Montserrat-SemiBold, Montserrat;
        font-weight: 600;
        line-height: 32px;
        text-align: center;
        cursor: pointer;
        color: #1b1b1b;
        border: 1px solid #dbdbdb;
    }
    .leavel_box {
        align-items: flex-start;
        .leavel_left {
            margin-left: 22px;
            .border_levael,
            .border_levael_m {
                width: 16px;
                height: 22px;
                border-left: 2px solid #eceef0;
                border-bottom: 2px solid #eceef0;
            }
            .border_levael_m {
                height: 45px;
            }
        }
        .leavel_right {
            margin-left: 14px;
            // padding-bottom: 10px;
            .tip_levael {
                font-size: 13px;
                font-family: Montserrat-SemiBold, Montserrat;
                font-weight: 600;
                color: #909090;
                line-height: 22px;
                margin: 10px 0 0 0;
            }
        }
    }
}
.add-reward-pool_main {
    .actionInfo_form {
        width: 920px;
        .form_tit {
            font-size: 24px;
            font-family: Montserrat-SemiBold, Montserrat;
            font-weight: 600;
            color: #1b1b1b;
            line-height: 22px;
            margin: 25px 0 20px 0;
        }
        .form_tip {
            font-size: 12px;
            font-family: Montserrat-SemiBold, Montserrat;
            font-weight: 600;
            color: #1b1b1b;
            line-height: 19px;
            margin: 20px 0 8px 0;
        }
        .form_tip_mid {
            font-size: 16px;
            font-family: Montserrat-SemiBold, Montserrat;
            font-weight: 600;
            color: #000000;
            line-height: 21px;
            margin-bottom: 20px;
        }
        .add_rewardBtn {
            width: 150px;
            height: 32px;
            border-radius: 17px 17px 17px 17px;
            opacity: 1;
            border: 1px solid #0085ff;
            line-height: 32px;
            text-align: center;
            font-size: 12px;
            font-family: Montserrat-SemiBold, Montserrat;
            font-weight: 600;
            color: #0085ff;
            cursor: pointer;
            margin: 20px 0 86px 0;
        }
        .reward_ul {
            width: 100%;
            .reward_item {
                display: flex;
                align-items: center;
                font-size: 14px;
                font-family: Montserrat-Medium, Montserrat;
                font-weight: 500;
                color: #181920;
                line-height: 22px;
                margin-bottom: 12px;
                .line_li {
                    flex: 1;
                    height: 1px;
                    background: #d8d8d8;
                    margin: 0 15px 0 20px;
                }
                .close_icon {
                    width: 22px;
                    min-width: 22px;
                    height: 22px;
                    background: url('../../../../assets/images/close-mini.png') no-repeat;
                    background-size: contain;
                    cursor: pointer;
                }
            }
        }
    }
}
.modal_form {
    padding: 10px 50px 50px 50px;
}
.del_tip {
    font-size: 16px;
    font-family: Montserrat-Medium, Montserrat;
    font-weight: 500;
    color: #1b1b1b;
    line-height: 24px;
    margin-top: 40px;
    text-align: center;
}
// add reward
.upload_imgBox {
    display: block;
    width: 170px;
    height: 170px;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    position: relative;
    border: 1px solid #c9cacc;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    #imgInput {
        position: absolute;
        z-index: -999;
        opacity: 0;
    }
}
.expiry_status {
    font-size: 12px;
    font-family: Montserrat-Medium, Montserrat;
    font-weight: 500;
    color: #101010;
    line-height: 16px;
    margin: 0 30px 0 10px;
}
.link_box {
    color: #0085ff;
    cursor: pointer;
    text-decoration: underline;
    user-select: none;
    font-size: 16px;
    font-family: Montserrat-Medium, Montserrat;
    font-weight: 500;
    margin-right: 20px;
}
