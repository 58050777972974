@font-face {
    font-family: MontserratSemiBold;
    src: url(./Montserrat/Montserrat-SemiBold.ttf);
}
@font-face {
    font-family: MontserratBold;
    src: url(./Montserrat/Montserrat-Bold.ttf);
}
@font-face {
    font-family: Montserrat-Bold;
    src: url(./Montserrat/Montserrat-Bold.ttf);
}
@font-face {
    font-family: Montserrat-Medium;
    src: url(./Montserrat/Montserrat-Medium.ttf);
}
@font-face {
    font-family: Montserrat-SemiBold;
    src: url(./Montserrat/Montserrat-SemiBold.ttf);
}
