.share_page {
    height: 100vh;
    .group_rangking {
        width: 100%;
        text-align: center;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.02) 0%, rgba(0, 0, 0, 0.75) 100%);
        h2 {
            margin-top: 10px;
            font-size: 7px;
            font-family: Montserrat-Bold;
            font-weight: bold;
            color: #1b1b1b;
            line-height: 15px;
        }
        h3 {
            font-size: 6px;
            font-family: Montserrat-SemiBold;
            font-weight: 600;
            color: #1b1b1b;
            line-height: 14px;
            margin-bottom: 8px;
        }
        .geo_logo {
            margin: 15px auto 10px;
            width: 92px;
            height: 22px;
        }
        .rangking_2 {
            width: 82px;
            .member_avatar {
                margin-top: 44px;
                width: 82px;
                height: 82px;
                border: 9px solid #f33d42;
                padding: 11px 10px 10px 11px;
            }
            .medal_box {
                z-index: 100;
                margin: -22px auto 0;
                width: 74px;
                height: 94px;
            }
        }
        .rangking_3 {
            width: 78px;
            margin-left: -12px;
            .member_avatar {
                margin-top: 70px;
                width: 78px;
                height: 78px;
                padding: 9px;
                border: 8px solid #4f73eb;
            }
            .sky_blue {
                width: 100%;
                height: 100%;
                background-color: rgba(0, 133, 255, 0.3);
            }
            .medal_box {
                margin: -17px auto 0;
                width: 52px;
                height: 65px;
            }
        }
        .rangking_1 {
            z-index: 99;
            width: 102px;
            margin-left: -16px;
            .member_avatar {
                width: 102px;
                height: 102px;
                padding: 11px 11px 12px 12px;
                border: 11px solid #ffbe3e;
            }
            .medal_box {
                width: 104px;
                height: 131px;
                margin: -23px auto 0;
            }
        }
        .rangking_1,
        .rangking_2,
        .rangking_3 {
            height: 210px;
        }
        .member_avatar {
            box-sizing: border-box;
            background-color: #fff;
        }
        .member_name {
            width: 100%;
            text-align: center;
            font-size: 10px;
            font-family: Montserrat-Medium;
            font-weight: 500;
            color: #ffffff;
            line-height: 15px;
            padding: 0 8px;
            word-wrap: break-word;
        }
        .top1 {
            width: 102px;
            margin-left: -16px;
        }
        .top2 {
            width: 82px;
        }
        .top3 {
            width: 78px;
            margin-left: -12px;
        }
    }
}
.geo_download_page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    .geo_download_logo {
        width: 100%;
        height: 50px;
        margin-bottom: 152px;
    }
    .to_appstore,
    .to_google {
        height: 70px;
        margin-top: 15px;
    }
}
