/*
 * @author        r.shouwei <r.shouwei@technehq.com>
 * @date          2023-05-08 10:37:09
 * Copyright © Techne All rights reserved
 */
.r-left {
    display: flex;
    align-items: center;
}
.r-top {
    display: flex;
    align-items: flex-start;
}
.r-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.marLeft {
    margin-left: auto;
}
.r-input {
    height: 48px;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #dbdbdb;
    font-size: 13px;
    font-family: Montserrat-Medium, Montserrat;
    font-weight: 500;
    color: #1b1b1b;
    padding-left: 20px;
    .ant-input {
        &::placeholder {
            font-size: 13px;
            font-family: Montserrat-Medium, Montserrat;
            font-weight: 500;
            color: #a4a4a4;
        }
        font-size: 13px;
        font-family: Montserrat-Medium, Montserrat;
        font-weight: 500;
        color: #1b1b1b;
    }
}

.r-input_serach {
    padding-left: 10px;
    svg {
        font-size: 20px;
        color: #d1d1d1;
    }
    .ant-input-suffix {
        align-items: center;
        .ant-input-clear-icon {
            width: 20px;
            height: 20px;
        }
    }
}
.r-textarea {
    border-radius: 4px 4px 4px 4px;
    // border: 1px solid #dbdbdb;
    position: relative;
    .ant-input {
        font-size: 13px;
        font-family: Montserrat-Medium, Montserrat;
        font-weight: 500;
        color: #1b1b1b;
    }
    .ant-input-data-count {
        position: absolute;
        bottom: 10px;
        right: 20px;
        margin-bottom: 0;
        font-size: 12px;
        font-family: Montserrat-Medium, Montserrat;
        font-weight: 500;
        color: #a7a7a7;
        line-height: 19px;
    }
}
.r-input_autoComplate {
    width: 100%;
    height: 48px;
    .ant-select-selector {
        height: 48px !important;
        border-radius: 4px 4px 4px 4px !important;
        padding-left: 20px !important;
        border: 1px solid #dbdbdb !important;
        .ant-select-selection-search {
            height: 48px;
            display: flex;
            align-items: center;
            left: 20px;
            .ant-select-selection-search-input {
                font-size: 13px;
                font-family: Montserrat-Medium, Montserrat;
                font-weight: 500;
                color: #1b1b1b;
            }
        }
        .ant-select-selection-placeholder {
            display: flex;
            align-items: center;
        }
    }
}
.r-switch {
    height: 31px;
    width: 51px;
    background: #ffffff;
    border: 1px solid rgba(16, 16, 16, 0.5);
    &:hover {
        background: #ffffff;
    }
    &:hover:not(.ant-switch-disabled) {
        background: #ffffff;
    }
    .ant-switch-handle {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.3);
        &::before {
            border-radius: 50%;
            background: #101010;
            // top: -1px;
        }
    }
    &.ant-switch-checked {
        border: 1px solid #299fff;
        background: #299fff;
        .ant-switch-handle {
            inset-inline-start: calc(100% - 27px);
            &::before {
                border-radius: 50%;
                background: #ffffff;
            }
        }
    }
}
.r-switch-mini {
    height: 24px;
    width: 40px;
    background: #ffffff;
    border: 1px solid rgba(16, 16, 16, 0.5);
    &:hover {
        background: #ffffff;
    }
    &:hover:not(.ant-switch-disabled) {
        background: #ffffff;
    }
    .ant-switch-handle {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        top: 2px;
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.3);
        &::before {
            border-radius: 50%;
            background: #101010;
        }
    }
    &.ant-switch-checked {
        border: 1px solid #299fff;
        background: #299fff;
        .ant-switch-handle {
            inset-inline-start: calc(100% - 20px);
            &::before {
                border-radius: 50%;
                background: #ffffff;
            }
        }
    }
}
.search_icon {
    width: 20px;
    height: 20px;
    background: url('../images/search.png') no-repeat;
    background-size: contain;
}
.clear_icon {
    width: 20px;
    height: 20px;
    background: url('../images/clear.png') no-repeat;
    background-size: contain;
    cursor: pointer;
}
.play_icon {
    width: 36px;
    height: 36px;
    background: url('../images/play.png') no-repeat;
    background-size: contain;
}
.r-select {
    .ant-select-selector {
        height: 48px !important;
        background: #ffffff;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #dbdbdb !important;
        .ant-select-selection-item {
            line-height: 46px;
            font-size: 13px;
            font-family: Montserrat-Medium, Montserrat;
            font-weight: 500;
            color: #1b1b1b;
        }
    }
    .ant-select-selection-placeholder {
        height: 48px !important;
        line-height: 48px !important;
    }
}
.r-input_sel {
    display: flex;
    align-items: center;
    svg {
        font-size: 20px;
        color: #d1d1d1;
    }
    .ant-select-clear {
        align-items: center;
        width: 20px;
        height: 20px;
        top: 41%;
        .ant-input-clear-icon {
            width: 20px;
            height: 20px;
        }
    }
}
.sel_arrow_icon {
    width: 18px;
    height: 18px;
    background: url('../images/down.png') no-repeat;
    background-size: contain;
}
.r-select_mini {
    .ant-select-selector {
        height: 32px !important;
        .ant-select-selection-item {
            line-height: 30px;
            font-size: 13px;
            font-family: Montserrat-Medium, Montserrat;
            font-weight: 500;
            color: #1b1b1b;
        }
    }
}
.r-rangePicker {
    height: 48px;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #dbdbdb;
    .sel_time_icon {
        width: 20px;
        height: 20px;
        background: url('../images/date.png') no-repeat;
        background-size: contain;
    }
    .ant-picker-clear {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ant-picker-input > input {
        text-align: center;
        &::placeholder {
            font-size: 13px;
            font-family: Montserrat-Medium, Montserrat;
            font-weight: 500;
            color: #a4a4a4;
        }
    }
    .ant-picker-range-separator {
        display: flex;
        align-items: center;
        .ant-picker-separator {
            display: none;
        }
        &::after {
            content: '';
            width: 6px;
            height: 2px;
            background: #a4a4a4;
        }
    }
}
.r-datePicker {
    height: 48px;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #dbdbdb;
    padding-left: 50px;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        left: 20px;
        width: 20px;
        height: 20px;
        background: url('../images/time-icon.png') no-repeat;
        background-size: contain;
    }
    .ant-picker-input > input {
        text-align: left;
        font-size: 13px;
        font-family: Montserrat-Medium, Montserrat;
        font-weight: 500;
        color: #1b1b1b;
        &::placeholder {
            font-size: 13px;
            font-family: Montserrat-Medium, Montserrat;
            font-weight: 500;
            color: #a4a4a4;
        }
    }
}
.r-modal {
    width: 500px;
    .modal-top-title {
        line-height: 24px;
        font-size: 14px;
        font-family: Montserrat-Bold, Montserrat;
        font-weight: bold;
        color: #1b1b1b;
        padding: 20px;
        border-bottom: 1px solid #f6f6f6;
    }
    .ant-modal-content {
        border-radius: 10px !important;
    }
}
.r-table {
    .ant-table-thead {
        .ant-table-cell {
            background-color: #ffffff;
            font-size: 13px;
            font-family: Montserrat-SemiBold, Montserrat;
            font-weight: 600;
            color: #767676;
            &::before {
                display: none;
            }
            // border-bottom: 2px solid #f6f6f6;
        }
    }
    .r-table_row {
        &::hover {
            background-color: #f1f3f5;
        }
        .ant-table-cell {
            // border-bottom: 2px solid #f6f6f6;
            // border-bottom: 1px solid #f6f6f6;
            font-size: 14px;
            font-family: Montserrat-SemiBold, Montserrat;
            font-weight: 600;
            color: #1b1b1b;
            transition: all 0.2s;
            white-space: pre;
        }
    }
    .hideBorder > .ant-table-cell {
        border-bottom: 1px solid #ffffff;
    }
    .r-expandedRowClassName > .ant-table-cell {
        background-color: #ffffff;
        // border-bottom: 2px solid #f6f6f6;
        // border-bottom: 1px solid #f6f6f6;
        padding-top: 0;
        &:hover {
            background-color: #ffffff;
        }
        .user_info {
            .user_img {
                width: 25px;
                height: 25px;
                border-radius: 25px 25px 25px 25px;
                opacity: 1;
                background-color: skyblue;
                object-fit: cover;
                margin-right: 10px;
            }
        }
    }
}
.pointer_table .r-table_row .ant-table-cell {
    cursor: pointer;
}
.initial_table .r-table_row .ant-table-cell {
    cursor: initial !important;
}
.r-default_btn,
.r-defaultBlick_btn,
.r-primary_btn {
    height: 48px;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #0085ff;
    font-size: 13px;
    font-family: Montserrat-SemiBold, Montserrat;
    font-weight: 600;
    color: #0085ff;
}
.r-defaultBlick_btn {
    border: 1px solid #dbdbdb;
    color: #1b1b1b;
}
.r-primary_btn {
    border: 1px solid #0085ff;
    color: #fff;
    background-color: #0085ff;
    &:disabled {
        border: 1px solid #0085ff;
        color: #fff;
        background-color: #0085ff;
        cursor: not-allowed;
        opacity: 0.4;
    }
}
.modal_form {
    position: relative;
    .r-slider {
        position: absolute;
        bottom: 180px;
        left: 110px;
    }
}
// table cell
.user_statistics_list,
.table_main {
    .user_info {
        .user_img {
            width: 25px;
            min-width: 25px;
            height: 25px;
            border-radius: 25px 25px 25px 25px;
            opacity: 1;
            // background-color: #5877f9;
            object-fit: cover;
            margin-right: 10px;
        }
        .user_img_text {
            width: 25px;
            height: 25px;
            border-radius: 25px 25px 25px 25px;
            background-color: #5877f9;
            font-size: 14px;
            font-family: Montserrat-SemiBold, Montserrat;
            font-weight: 600;
            color: #ffffff;
            margin-right: 10px;
            text-align: center;
            line-height: 25px;
        }
    }
}
.user_img {
    min-width: 25px;
}
.form_tip {
    font-size: 12px;
    font-family: Montserrat-SemiBold, Montserrat;
    font-weight: 600;
    color: #1b1b1b;
    line-height: 19px;
    margin: 20px 0 8px 0;
}
.hide_css {
    display: none !important;
}
.oneLine_text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
