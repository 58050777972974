.clear:after {
    content: '';
    width: 0;
    height: 0;
    display: block;
    clear: both;
}
ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}
h1,
h2,
h3,
h4,
h5,
p {
    margin: 0;
    padding: 0;
    word-break: break-word;
}
* {
    padding: 0;
    margin: 0;
}
.popup-bubble {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -100%);
    min-width: 146px;
    border-radius: 4px;
    width: 146px;
    background: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 2px 8px;
    .store_name {
        font-size: 12px;
        font-family: Montserrat-Medium;
        font-weight: 500;
        color: #1b1b1b;
        line-height: 20px;
        margin-bottom: 0;
    }
}
.popup-bubble-anchor::after {
    content: '';
    position: absolute;
    top: 0;
    left: -50px;
    /* Center the tip horizontally. */
    transform: translate(-50%, 0);
    /* The tip is a https://css-tricks.com/snippets/css/css-triangle/ */
    width: 0;
    height: 0;
    /* The tip is 8px high, and 12px wide. */
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 7px solid white;
}
.popup-container {
    cursor: auto;
    height: 0;
    position: absolute;
    /* The max width of the info window. */
    width: 200px;
}
