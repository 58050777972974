.ant-picker-range-separator {
    padding: 0 !important;
}
.ant-image-preview-img-wrapper {
    img {
        width: auto;
        height: auto;
    }
}
.ant-badge-count {
    font-size: 10px !important;
    font-family: MontserratBold;
    letter-spacing: 1px;
}
.modal_form_w400 {
    .ant-form {
        width: 400px;
        padding: 30px 0 50px;
        margin: 0 auto;
    }
    .modal-top-title {
        height: 60px;
        font-size: 14px;
        font-family: MontserratBold;
        font-weight: bold;
        color: #1b1b1b;
        line-height: 60px;
        padding-left: 16px;
        border-bottom: 2px solid #f6f6f6;
    }
}
.modal-forgot {
    margin-top: 122px;
}
.is_clickable {
    .ant-table-tbody {
        .ant-table-cell {
            cursor: pointer;
        }
    }
}
.no_border {
    .ant-table-tbody > tr > td {
        border-bottom: none;
    }
}
.ant-table-tbody > .ant-table-row-level-1 > td {
    .indent-level-1 {
        padding-left: 12px !important;
    }
    .columns_num {
        display: none;
    }
    // border-bottom: none !important;
    font-size: 13px !important;
    color: rgba(27, 27, 27, 0.6) !important;
    position: relative;
    .table_cell_line_vertical {
        position: absolute;
        top: 0;
        width: 1px;
        height: 100%;
        background: #eceef0;
    }
    .table_cell_line_horizontal {
        width: 16px;
        height: 1px;
        background: #eceef0;
        margin-right: 7px;
    }
}
.submit-btn {
    width: 194px;
    height: 54px;
    border-radius: 4px;
}
.default_btn {
    height: 48px;
    border-radius: 4px;
}
.w-100 {
    width: 100%;
}
button {
    font-family: MontserratBold !important;
}
.table_page {
    height: calc(100vh - 80px - 32px);
    display: flex;
    flex-direction: column;
}
.page-main {
    .top-title {
        font-size: 36px;
        font-family: MontserratBold;
        font-weight: bold;
        color: #1b1b1b;
        line-height: 24px;
        margin: 50px 0;
    }
    .come_back {
        cursor: pointer;
        img {
            width: 18px;
            height: 18px;
            vertical-align: text-top;
            margin-right: 8px;
        }
        font-size: 16px;
        font-family: Montserrat-SemiBold;
        font-weight: 600;
        color: rgba(27, 27, 27, 0.6);
        line-height: 18px;
    }
    .t72_b50 {
        margin: 72px 0 50px;
    }
    .t57_b25 {
        margin: 57px 0 25px;
    }
    .view_more {
        font-size: 14px;
        font-family: MontserratSemiBold;
        font-weight: 600;
        color: #0085ff;
        line-height: 16px;
        cursor: pointer;
        text-decoration: underline;
    }
    .main_round_box {
        height: 632px;
        border-radius: 20px;
        border: 1px solid #dbdbdb;
        padding: 24px 20px;
        .content_box_item_header {
            .title {
                font-size: 24px;
                font-family: MontserratBold;
                font-weight: bold;
                color: #1b1b1b;
                line-height: 24px;
                margin-bottom: 24px;
            }
        }
    }
    .select_tabs {
        &_item {
            width: 124px;
            height: 35px;
            border-radius: 4px;
            font-size: 12px;
            font-family: Montserrat-SemiBold;
            color: rgba(27, 27, 27, 0.6);
            font-weight: 600;
            line-height: 35px;
            text-align: center;
            cursor: pointer;
            position: relative;
            transition: all 0.2s;
            .new_report {
                position: absolute;
                left: 4px;
                top: 15px;
                width: 6px;
                height: 6px;
                background: #ff3b30;
                border-radius: 50%;
            }
        }
        .tab_active {
            background: #0085ff;
            color: #ffffff;
            .new_report {
                background: #0085ff;
            }
        }
    }
}
.search_box {
    margin-bottom: 30px;
    .search_input {
        height: 48px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        padding: 14px 10px;
        font-family: Montserrat-Medium;
        font-size: 13px !important;
        ::placeholder {
            font-size: 13px;
            font-family: Montserrat-Medium;
            font-weight: 500;
            color: rgba(27, 27, 27, 0.4);
            line-height: 15px;
        }
        .ant-input-prefix {
            width: 20px;
            height: 20px;
        }
    }
    .ant-input-clear-icon {
        font-size: 20px;
    }
    .ant-select {
        .ant-select-selector {
            height: 48px;
            .ant-select-selection-search-input {
                height: 100%;
            }
            .ant-select-selection-item {
                font-size: 13px;
                font-family: Montserrat-Medium;
                font-weight: 500;
                color: #1b1b1b;
                line-height: 46px;
            }

            .ant-select-selection-placeholder {
                font-size: 13px;
                font-family: Montserrat-Medium;
                font-weight: 500;
                color: rgba(27, 27, 27, 0.4);
                line-height: 46px;
            }
        }
    }
    .ant-picker {
        height: 48px;
    }
}
.modal-suspend {
    .ant-modal-content {
        width: 500px;
        // height: 276px;
        border-radius: 10px;
        padding: 50px;
    }
}

.modal-tip {
    width: 400px;
    font-size: 16px;
    font-family: Montserrat-Medium;
    font-weight: 500;
    color: #1b1b1b;
    line-height: 24px;
    word-wrap: break-word;
    text-align: center;
    margin: 0 auto;
}
.forgot_password_modal_tip {
    width: 400px;
    margin: 0 auto;
    font-size: 14px;
    font-family: Montserrat-Medium;
    font-weight: 500;
    color: rgba(27, 27, 27, 0.6);
    line-height: 22px;
    margin-top: 30px;
}
.form_title {
    font-size: 28px;
    font-family: Montserrat-Bold;
    font-weight: bold;
    color: #1b1b1b;
    text-align: center;
}
.form_card_tip {
    font-size: 14px;
    font-family: Montserrat-Medium;
    font-weight: 500;
    color: rgba(27, 27, 27, 0.6);
    text-align: center;
}
.ant-picker-range {
    .ant-picker-input {
        max-width: 90px;
        > input {
            text-align: center;
        }
    }
    .ant-picker-suffix {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        inset-inline-end: 11px;
    }
    .ant-picker-range-separator {
        display: flex;
        align-items: center;
        .ant-picker-separator {
            display: none;
        }
        &::after {
            content: '';
            width: 6px;
            height: 2px;
            background: #a4a4a4;
        }
    }
}
.ant-picker {
    border-radius: 4px !important;
    .ant-picker-input {
        font-size: 13px;
        font-family: Montserrat-Medium;
        font-weight: 500;
        color: #1b1b1b;
        line-height: 15px;
        > input {
            &::placeholder {
                font-size: 13px;
                font-family: Montserrat-Medium;
                font-weight: 500;
                color: rgba(27, 27, 27, 0.4);
            }
        }
    }

    .ant-picker-clear {
        svg {
            width: 18px;
            height: 18px;
        }
    }
}
.ant-select-selector {
    border-radius: 4px !important;
}
// --------------------------------------------------------
.webkit_clamp_2 {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}
.webkit_clamp_1 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
}
.ant-form-item-label {
    > label {
        color: #1b1b1b !important;
        font-size: 12px !important;
        font-family: MontserratSemiBold;
        font-weight: 600;
        letter-spacing: 0.28px;
    }
}
.ant-form-item .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: '';
}
.default_input {
    font-size: 14px;
    font-family: MontserratSemiBold;
    font-weight: 400;
    color: #141414;
    line-height: 16px;
    width: 100%;
    height: 46px;
    border-radius: 4px;
    .ant-input {
        font-family: MontserratSemiBold;
    }
}
.ant-input-wrapper {
    height: 48px;
    .ant-input-group-addon {
        font-size: 14px;
        font-family: MontserratSemiBold;
        font-weight: 400;
        color: #141414;
        background: transparent;
        padding: 0 0 0 11px;
        border-radius: 12px;
    }

    .ant-input {
        height: 100%;
        border-left: none;
        padding: 4px 11px 4px 2px;
        border-radius: 12px;
    }
}
.ant-form-item {
    margin-bottom: 12px;
    .ant-form-item-explain-error {
        display: none;
    }
    .ant-input-affix-wrapper-status-error {
        .ant-input-prefix {
            color: #141414 !important;
        }
    }
}
.ant-space-item {
    letter-spacing: 0.2px;
    .selected_type {
        font-size: 12px;
        font-family: MontserratSemiBold;
        font-weight: 400;
        color: #141414;
        .ant-space-item {
            font-size: 12px;
            font-family: MontserratSemiBold;
        }
    }
    img {
        width: 18px;
        height: 18px;
        vertical-align: middle;
    }
}
.form-select {
    height: 48px;
    .ant-select-selector {
        height: 48px !important;
        border-radius: 12px;
    }
}
.modal-title {
    font-size: 20px;
    font-family: MontserratSemiBold;
    font-weight: 600;
    color: #141414;
    line-height: 23px;
    letter-spacing: 0.75px;
    margin: 50px 0 10px;
    text-align: center;
}

.modal_no_padding {
    .ant-modal-content {
        padding: 0;
    }
}
.code_img {
    width: 120px;
    height: 48px;
    background: #dfdfdf;
    border-radius: 12px;
    cursor: pointer;
}
.sort {
    font-family: MontserratSemiBold;
    font-size: 12px;
    font-weight: 400;
    color: #828282;
}
.ant-form-item .ant-select-selection-item,
.ant-select-selection-placeholder {
    font-family: Montserrat-Medium;
    font-size: 13px;
    font-weight: 500;
    color: #1b1b1b;
    line-height: 15px;
}
.ant-input-password {
    .ant-input-suffix {
        display: none;
    }
}
.ant-dropdown-menu-item,
.ant-select-item-option-content,
.ant-select-selection-item,
.ant-tabs-tab-btn {
    font-family: Montserrat-Medium;
}
.ant-dropdown-menu-item .ant-dropdown-menu-title-content .ant-space .ant-space-item {
    font-family: MontserratSemiBold;
}
.ant-tabs-tab-btn {
    font-size: 12px;
    letter-spacing: 0.2px;
    color: #828282;
}
.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #141414 !important;
}
.ant-input-number-input-wrap {
    height: 100%;
    .ant-input-number-input {
        height: 100%;
    }
}
.ant-modal .ant-modal-header {
    border-radius: 24px;
}
.brokers_table,
.clients_table {
    .ant-row {
        cursor: pointer;
    }
}
.data_loading,
.not_data {
    min-height: 100px;
    font-size: 14px;
    font-family: MontserratSemiBold;
    font-weight: 400;
    color: #141414;
    line-height: 16px;
    letter-spacing: 0.22px;
}

.client-detail,
.broker-detail {
    .top-title {
        margin-bottom: 15px;
    }
    .broker-card,
    .client-card {
        min-height: 167px;
        background: #ffffff;
        box-shadow: 0px 5px 18px 0px rgba(0, 0, 0, 0.06);
        border-radius: 24px;
        &-left {
            font-size: 12px;
            font-family: MontserratSemiBold;
            font-weight: 400;
            color: #141414;
            line-height: 14px;
            .borker-avatar {
                width: 72px;
                height: 72px;
                border-radius: 50%;
                margin-right: 16px;
                img {
                    border-radius: 50%;
                }
            }
            .broker-info {
                .broker-name {
                    font-size: 20px;
                    font-family: MontserratSemiBold;
                    font-weight: 400;
                    color: #141414;
                    line-height: 40px;
                }
                .broker-phone,
                .broker-create-date {
                    line-height: 24px;
                }
            }
        }
        &-right {
            height: 100%;
            text-align: right;
            .operate-btn {
                margin-top: 60px;
                margin-left: 12px;
                min-width: 92px;
                height: 33px;
                border-radius: 17px;
            }
        }
    }
    .broker-card {
        padding: 23px 16px 22px 24px;
        margin-bottom: 43px;
    }
}

.ant-tabs-content-holder {
    margin: 0 -24px 0 -24px;
}
