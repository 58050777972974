/*
 * @author        r.shouwei <r.shouwei@technehq.com>
 * @date          2023-05-09 16:00:00
 * Copyright © Techne All rights reserved
 */
.brands_main {
    padding-top: 50px;
    .workout_top {
        margin-bottom: 40px;
        .r-default_btn {
            position: relative;
            .roundNum_box {
                position: absolute;
                padding: 0 5px;
                min-width: 26px;
                height: 20px;
                background: #ff3b30;
                border-radius: 60px 60px 60px 60px;
                top: -10px;
                right: -1px;
                font-size: 10px;
                font-family: Montserrat-Bold, Montserrat;
                font-weight: bold;
                color: #ffffff;
                // letter-spacing: 1px;
                text-align: center;
                line-height: 20px;
            }
        }
    }
    .action_tab {
        margin-bottom: 20px;
        .action_tab_item {
            width: 145px;
            text-align: center;
            padding: 8px 18px;
            font-size: 12px;
            font-family: Montserrat-SemiBold, Montserrat;
            font-weight: 600;
            color: #767676;
            line-height: 18px;
            position: relative;
            border-radius: 4px;
            cursor: pointer;
            user-select: none;
            transition: all 0.2s;
            &:nth-child(2) {
                margin: 0 20px;
            }
            &.active {
                background: #0085ff;
                color: #ffffff;
            }
        }
    }
    .table_main {
        .remove_btn {
            width: 99px;
            height: 32px;
            border-radius: 17px 17px 17px 17px;
            opacity: 1;
            border: 1px solid #dbdbdb;
            text-align: center;
            line-height: 32px;
            font-size: 12px;
            font-family: Montserrat-SemiBold, Montserrat;
            font-weight: 600;
            color: #1b1b1b;
            user-select: none;
            cursor: pointer;
        }
    }
}
.space15 {
    width: 15px;
    min-width: 15px;
    height: 100%;
}
//main back
.list_main {
    padding-top: 57px;
    .space15 {
        width: 15px;
        height: 100%;
    }
    .mar20 {
        margin: 20px 0;
    }
    .top_back {
        .back_box {
            font-size: 16px;
            font-family: Montserrat-SemiBold, Montserrat;
            font-weight: 600;
            color: #1b1b1b;
            width: 68px;
            cursor: pointer;
            .back_icon {
                width: 18px;
                height: 18px;
                background: url('../../../../assets/images/back.png') no-repeat;
                background-size: contain;
                cursor: pointer;
                margin-right: 8px;
            }
        }
    }
}
.actionInfo_form {
    width: 920px;
    .form_tit {
        font-size: 24px;
        font-family: Montserrat-SemiBold, Montserrat;
        font-weight: 600;
        color: #1b1b1b;
        line-height: 22px;
        margin: 25px 0 20px 0;
    }
    .form_tip {
        font-size: 12px;
        font-family: Montserrat-SemiBold, Montserrat;
        font-weight: 600;
        color: #1b1b1b;
        line-height: 19px;
        margin: 20px 0 8px 0;
    }
    .form_tip_mid {
        font-size: 16px;
        font-family: Montserrat-SemiBold, Montserrat;
        font-weight: 600;
        color: #000000;
        line-height: 21px;
        margin-bottom: 20px;
    }
    .add_rewardBtn {
        width: 150px;
        height: 32px;
        border-radius: 17px 17px 17px 17px;
        opacity: 1;
        border: 1px solid #0085ff;
        line-height: 32px;
        text-align: center;
        font-size: 12px;
        font-family: Montserrat-SemiBold, Montserrat;
        font-weight: 600;
        color: #0085ff;
        cursor: pointer;
        margin: 20px 0 86px 0;
    }
    .reward_ul {
        width: 100%;
        .reward_item {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-family: Montserrat-Medium, Montserrat;
            font-weight: 500;
            color: #181920;
            line-height: 22px;
            margin-bottom: 12px;
            .line_li {
                flex: 1;
                height: 1px;
                background: #d8d8d8;
                margin: 0 15px 0 20px;
            }
            .close_icon {
                width: 22px;
                height: 22px;
                background: url('../../../../assets/images/close-mini.png') no-repeat;
                background-size: contain;
                cursor: pointer;
            }
        }
    }
}
.modal_form {
    padding: 10px 50px 50px 50px;
}
.del_tip {
    font-size: 16px;
    font-family: Montserrat-Medium, Montserrat;
    font-weight: 500;
    color: #1b1b1b;
    line-height: 24px;
    margin-top: 40px;
    text-align: center;
}
.upload_imgBox {
    display: block;
    width: 170px;
    height: 170px;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    position: relative;
    border: 1px solid #c9cacc;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    #imgInput {
        position: absolute;
        z-index: -999;
        opacity: 0;
    }
}
.expiry_status {
    font-size: 12px;
    font-family: Montserrat-Medium, Montserrat;
    font-weight: 500;
    color: #101010;
    line-height: 16px;
    margin: 0 30px 0 10px;
}
.remove_btn {
    width: 99px;
    height: 32px;
    border-radius: 17px 17px 17px 17px;
    opacity: 1;
    border: 1px solid #dbdbdb;
    text-align: center;
    line-height: 32px;
    font-size: 12px;
    font-family: Montserrat-SemiBold, Montserrat;
    font-weight: 600;
    color: #1b1b1b;
    user-select: none;
    cursor: pointer;
}
.gro_tip {
    font-size: 12px;
    font-family: Montserrat-Medium, Montserrat;
    font-weight: 500;
    color: #616161;
    line-height: 19px;
    margin-left: 13px;
}
.offerDetail_detail_main {
    padding-top: 57px;
    .detail_box {
        .info_row {
            margin-bottom: 20px;
            &:last-child {
                margin: 0;
            }
            .info_tip,
            .info_msg {
                font-size: 16px;
                font-family: Montserrat-Medium, Montserrat;
                font-weight: 500;
                color: #767676;
                line-height: 22px;
            }
            .info_tip {
                min-width: max-content;
            }
            .info_msg {
                color: #1b1b1b;
                margin-left: 20px;
            }
            .no-margin {
                margin: 0;
            }
            .reward_name {
                font-size: 24px;
                font-family: Montserrat-SemiBold, Montserrat;
                font-weight: 600;
                color: #1b1b1b;
                line-height: 28px;
                margin-top: 10px;
                .reward_img {
                    width: 78px;
                    min-width: 78px;
                    height: 78px;
                    margin-right: 15px;
                    background: #cecece;
                    object-fit: cover;
                    border-radius: 4px 4px 4px 4px;
                }
            }
        }
        .workouts_action {
            width: 100%;
            // min-height: 234px;
            background: #ffffff;
            border-radius: 20px 20px 20px 20px;
            opacity: 1;
            border: 1px solid #dbdbdb;
            padding: 24px 30px;
            .action_tit {
                font-size: 24px;
                font-family: Montserrat-Bold, Montserrat;
                font-weight: bold;
                color: #1b1b1b;
                line-height: 24px;
                margin-bottom: 20px;
            }
            .action_setBox {
                .action_setTit {
                    font-size: 16px;
                    font-family: Montserrat-SemiBold, Montserrat;
                    font-weight: 600;
                    color: #000000;
                    line-height: 21px;
                    margin-top: 20px;
                }
                .action_labelBox {
                    margin: 10px 0;
                    .action_label {
                        font-size: 16px;
                        font-family: Montserrat-Medium, Montserrat;
                        font-weight: 500;
                        color: #1b1b1b;
                        line-height: 22px;
                    }
                }
            }
            .workout_nameList {
                flex-wrap: wrap;
            }
            .workout_name {
                font-size: 16px;
                font-family: Montserrat-Medium, Montserrat;
                font-weight: 500;
                color: #0085ff;
                line-height: 22px;
                margin-right: 38px;
                text-decoration: underline;
                cursor: pointer;
                user-select: none;
            }
        }
        .topNum_ul_NEW {
            width: 100%;
            .wordout_card {
                width: 100%;
                height: 150px;
                background: #ffffff;
                border-radius: 20px 20px 20px 20px;
                border: 1px solid #dbdbdb;
                padding: 30px;
                .card_left {
                    .card_label {
                        width: 210px;
                        font-size: 16px;
                        font-family: Montserrat-Medium, Montserrat;
                        font-weight: 500;
                        color: #1b1b1b;
                        line-height: 22px;
                        margin-bottom: 10px;
                    }
                }
                .card_val {
                    font-size: 42px;
                    font-family: Montserrat-SemiBold, Montserrat;
                    font-weight: 600;
                    color: #1b1b1b;
                    line-height: 24px;
                }
            }
            .space_box {
                margin-top: 20px;
            }
        }
        .workout-location-statistics {
            width: 100%;
            background: #ffffff;
            border-radius: 20px 20px 20px 20px;
            padding: 23px 30px;
            border: 1px solid #dbdbdb;
            .location_statisticsTip {
                font-size: 16px;
                font-family: Montserrat-Medium, Montserrat;
                font-weight: 500;
                color: #767676;
                line-height: 22px;
                .view_box {
                    font-size: 14px;
                    font-family: Montserrat-SemiBold, Montserrat;
                    font-weight: 600;
                    color: #0085ff;
                    text-decoration: underline;
                    cursor: pointer;
                    margin-left: auto;
                    margin-right: 10px;
                    user-select: none;
                }
                .downIcon_btm {
                    width: 16px;
                    height: 16px;
                    background: url('../../../../assets/images/down.png') no-repeat;
                    background-size: contain;
                    cursor: pointer;
                    transition: all 0.5s;
                }
                .roteIcon {
                    transform: rotate(180deg);
                }
            }
            .workout_list {
                .workout_item_header {
                    padding: 20px 0 15px 0;
                    .itemHeader_cell {
                        font-size: 13px;
                        font-family: Montserrat-SemiBold, Montserrat;
                        font-weight: 600;
                        color: #767676;
                        line-height: 22px;
                        flex: 3;
                        &:last-child {
                            flex: 1;
                        }
                    }
                }
                .workout_item {
                    margin-bottom: 18px;
                    .item_cell {
                        flex: 3;
                        font-size: 14px;
                        font-family: Montserrat-SemiBold, Montserrat;
                        font-weight: 600;
                        color: #1b1b1b;
                        &:last-child {
                            flex: 1;
                        }
                        .user_img {
                            width: 24px;
                            height: 24px;
                            object-fit: cover;
                            border-radius: 60px 60px 60px 60px;
                            margin-right: 9px;
                            background: skyblue;
                        }
                    }
                    .item_cellMini {
                        font-size: 12px;
                        font-family: Montserrat-Medium, Montserrat;
                        font-weight: 500;
                        color: #767676;
                    }
                }
            }
            .user_listBox {
                height: 0;
                transition: all 1s;
            }
            .showList {
                height: auto;
            }
        }
    }
}
