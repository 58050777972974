.r-slider {
    width: 267px;
    height: 6px;
    background: #6c90b0;
    border-radius: 20px 20px 20px 20px;
    opacity: 1;
    position: relative;
    cursor: pointer;
}
.r-knob {
    width: 81px;
    height: 23px;
    background: #ffffff;
    box-shadow: -3px 3px 7px 0px rgba(2, 54, 68, 0.34);
    border-radius: 4px 4px 4px 4px;
    position: absolute;
    top: -8px;
    left: 0;
    font-size: 12px;
    font-family: Montserrat-Bold, Montserrat;
    font-weight: bold;
    color: #0085ff;
    text-align: center;
    line-height: 23px;
}
