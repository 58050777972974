.pagination {
    margin-top: 20px;
    height: 58px;
    padding: 0 20px;
    font-size: 14px;
    font-family: Montserrat-Medium;
    font-weight: 400;
    color: rgba(27, 27, 27, 0.6);
    line-height: 24px;
    .pageNum-box {
        width: 67px;
        height: 32px;
        border-radius: 4px;
        opacity: 1;
        border: 1px solid #e6e6e8;
        margin-left: 12px;
    }
    .left-icon,
    .right-icon {
        margin-left: 8px;
    }
    .pageBtnIcon {
        width: 16px;
        height: 16px;
        background-image: url('../../assets/images/left.png');
        background-size: contain;
        // cursor: pointer;
    }
    .next_icon {
        background-image: url('../../assets/images/right.png');
    }
}
