.delete_app_account {
    width: 100%;
    height: 100vh;
    .logo {
        background: #0d0d0d;
        padding: 25px 60px;
        img {
            width: 170px;
            height: 42px;
        }
    }
    .loadingNew_box {
        height: calc(100vh - 95px);
        .ant-spin {
            height: calc(100vh - 95px);
            max-height: 100%;
        }
    }
    .delete_main {
        height: calc(100vh - 95px);
        .step-box {
            max-width: 660px;
            .title_del {
                font-size: 32px;
                font-family: Montserrat-Bold;
                font-weight: bold;
                color: #1b1b1b;
                line-height: 24px;
                text-align: center;
                margin: 0;
            }
            .tip_del {
                text-align: center;
                font-size: 18px;
                font-family: Montserrat-Medium;
                font-weight: 500;
                color: #1b1b1b;
                line-height: 19px;
            }
            .marStepBox {
                margin: 50px 0 120px 0;
            }
            .btnBox {
                position: relative;
                #buttonDiv {
                    position: absolute;
                    left: 0;
                    z-index: 5;
                    opacity: 0;
                }
            }
            .r-del_pageBtn {
                margin: 0 auto;
                &:hover {
                    color: #ffffff;
                }
            }
            // step 2
            .r-otherLogin_btn {
                width: 343px;
                height: 40px;
                background: #4285f4;
                border-radius: 2px 2px 2px 2px;
                opacity: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 15px;
                font-family: Montserrat-bold;
                font-weight: bold;
                color: #ffffff;
                user-select: none;
                cursor: pointer;
                position: relative;
                .bewIcon {
                    width: 30px;
                    height: 30px;
                    position: absolute;
                    left: 10px;
                }
                .googleIcon {
                    background: url('../../assets/images/google.png') no-repeat;
                    background-size: contain;
                }
                .facebookIcon {
                    background: url('../../assets/images/facebook.png') no-repeat;
                    background-size: contain;
                }
            }
        }
    }
}
