.setting {
    &-item {
        height: 68px;
        border-radius: 20px;
        border: 1px solid #dbdbdb;
        margin-bottom: 20px;
        padding: 23px 30px;
        cursor: pointer;

        .left-label {
            font-size: 16px;
            font-family: MontserratSemiBold;
            font-weight: 600;
            color: #1b1b1b;
            line-height: 22px;
        }
        :hover {
            background: #f1f3f5;
        }
    }
    &-item:hover {
        background: #f1f3f5;
    }
}
