.upload-version-form {
    .ant-input-data-count {
        position: absolute;
        bottom: 10px;
        right: 20px;
        font-size: 12px;
        font-family: Montserrat-Medium;
        font-weight: 500;
        color: rgba(27, 27, 27, 0.4);
        margin-bottom: 0;
    }
    .ant-select-selection-item {
        line-height: 46px !important;
    }
}
