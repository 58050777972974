.table_box {
    .ant-table-thead {
        position: sticky;
        top: 0;
        z-index: 1;
        .ant-table-cell {
            font-size: 13px;
            font-family: Montserrat-SemiBold;
            font-weight: 600;
            color: rgba(27, 27, 27, 0.6);
            line-height: 22px;
            background: #fff;
            border: none;
            white-space: nowrap;
        }
        ::before {
            width: 0 !important;
            height: 0 !important;
        }
    }
    .ant-table-tbody {
        .ant-table-cell {
            font-size: 14px;
            font-family: Montserrat-SemiBold;
            font-weight: 600;
            color: #1b1b1b;
            line-height: 22px;
            .user_info {
                display: flex;
                img {
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                    vertical-align: middle;
                    margin-right: 10px;
                }
            }
            .table_button {
                width: 99px;
                height: 32px;
                border-radius: 17px;
                font-size: 12px;
                font-family: Montserrat-SemiBold !important;
                font-weight: 600;
                line-height: 14px;
            }
            .active_btn {
                color: #0ab845;
                border-color: #0ab845;
                :hover {
                    opacity: 0.6;
                }
            }
        }
    }
}
.flex_height {
    flex: 1;
    overflow: hidden;
    overflow-y: auto;
}
.default_height {
    height: 486px;
    // .ant-table-thead {
    //     .ant-table-cell {
    //         padding: 10px;
    //     }
    // }
    // .ant-table-tbody {
    //     .ant-table-cell {
    //         padding: 10px;
    //     }
    // }
    .ant-table-cell {
        padding: 9px !important;
    }
}
