.layout-container {
    min-height: 100vh;
    min-width: 1280px;
    background: #fff;
    .header {
        height: 80px;
        background: #1a1a1a !important;
        padding: 0 60px;
        display: flex;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 999;
        .logo {
            width: 130px;
            min-width: 130px;
            height: 30px;
            margin-right: 70px;
            img {
                min-width: 130px;
                vertical-align: top;
            }
        }
        .menu_items {
            min-width: 1000px;
            background: transparent;
            height: 78px;
            line-height: 78px;
            .ant-menu-item {
                font-size: 13px;
                font-family: MontserratBold;
                font-weight: bold;
                color: rgba(255, 255, 255, 0.5);
            }
            .ant-menu-item:hover {
                color: rgba(0, 133, 255, 0.6);
                &::after {
                    border-bottom-width: 4px;
                    border-radius: 2px 2px 0 0;
                }
            }
            .ant-menu-item-selected {
                color: #0085ff;
                &::after {
                    border-bottom-width: 4px;
                    border-radius: 2px 2px 0 0;
                }
            }
        }
        .user_module {
            position: absolute;
            right: 60px;
            display: flex;
            align-items: center;
            .ant-space-item {
                height: 36px;
            }
            .user_avatar {
                width: 36px;
                height: 36px;
                background: #0085ff;
                border-radius: 50%;
                text-align: center;
                font-size: 20px;
                font-family: Montserrat-SemiBold;
                font-weight: 600;
                color: #ffffff;
                line-height: 36px;
            }
            .ArrowsUp {
                width: 24px;
                height: 24px;
                vertical-align: super;
                cursor: pointer;
            }
            .arrowTransform {
                transition: 0.2s;
                transform: rotate(180deg);
            }

            .arrowTransformReturn {
                transition: 0.2s;
                transform: rotate(0deg);
            }
        }
    }
    .layout-bottom {
        margin: 80px 60px 32px;
        .ant-layout {
            background-color: #fff !important;
        }
    }
}
.user_popover {
    height: 179px;
    border-radius: 8px;
    .user_email {
        border-bottom: 2px solid #f6f6f6;
        font-size: 14px;
        font-family: Montserrat-Medium;
        font-weight: 500;
        color: #212121;
        line-height: 68px;
        padding: 0 8px;
    }
    .update_password,
    .log_out {
        font-size: 16px;
        font-family: Montserrat-Medium;
        font-weight: 500;
        color: #212121;
        line-height: 52px;
        padding: 0 8px;
        cursor: pointer;
        border-radius: 8px;
    }
    .update_password:hover,
    .log_out:hover {
        background: #f1f3f5;
    }
}
.modal-logout {
    .ant-modal-content {
        height: 228px !important;
    }
}
